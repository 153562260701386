import React from 'react';
import { Link } from 'react-scroll';
import btnImg from "../../assets/hireme.png"
import resume from "../../assets/Jake_Cillay_Resume.docx.pdf"

import './intro.css';

const Intro = () => {
  return (
    <section id="intro">
      <div className='introContent'>
        <span className="address">Hello, </span>
        <span className="introText">
          I'm Jake <span className='introName'>Cillay,</span> <br/>a Software Engineer. </span>
        <p className='introPara'>
        I'm a passionate and skilled software engineer with a Bachelor's degree
        in Computer Science from Pitzer College. With over two years of
        hands-on experience in the field, I have had the privilege of working on
        a diverse range of projects that have honed my problem-solving abilities
        and coding prowess. From developing efficient algorithms to creating
        user-friendly applications, I thrive on the challenges that the world of
        software engineering presents. This portfolio serves as a showcase of
        my journey, featuring a selection of projects that reflect my dedication
        to crafting high-quality software solutions. Explore my work, delve into
         my skills, and join me in my continuous pursuit of innovation in the
         ever-evolving realm of technology.
        </p>
        <a href={resume} download="Jake Cillay's Resume" target="_blank" rel="noreferrer">
          <button className="hireMeBtn">
            <img src={btnImg} alt="Hire Me" className="hireMeImg"/> Download Resume
          </button>
        </a>
      </div>
    </section>
  )
}

export default Intro
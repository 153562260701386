import React from "react";

import './skills.css'

import UIDesign from '../../assets/ui-design.png'
import WebDesign from '../../assets/backend.png'
import AppDesign from '../../assets/api.png'

const Skills = () => {
    return (
        <section id='skills'>
            <span className="skillTitle">What I do Professionally</span>
            <span className="skillDesc">
                I am currently a startup engineer at a financial/algorithmic trading company.
                For over two years, I've worked and developed in all nooks and crannies of
                our code base. Below are a few skills that I've developed while working
                for my company:
            </span>
            <div className="skillBars">
                <div className="skillBar">
                    <img src={AppDesign} className="skillBarImg white-img" alt="api"/>
                    <div className="skillBarText">
                        <h2>API Construction</h2>
                        <p>Developed a complete Flask REST API for quickly gathering,
                           uploading, and caching data from the crypto and stock
                           markets. Created a client that could be modularized
                           and imported into different to access this API.
                        </p>
                    </div>
                </div>
                <div className="skillBar">
                    <img src={UIDesign} className="skillBarImg"  alt="ui/ux"/>
                    <div className="skillBarText">
                        <h2>UI/UX Design & Development</h2>
                        <p>Designed a completely customizable library to plot
                           Bokeh charts and figures for statistical financial data,
                           trading strategy backtesting, and market data.</p>
                    </div>
                </div>
                <div className="skillBar">
                    <img src={WebDesign} className="skillBarImg white-img"  alt="Full-Stack"/>
                    <div className="skillBarText">
                        <h2>Full Stack Development</h2>
                        <p>Built a full stack application with a stack consisting
                           of Python, Flask, Javascript and a Postgres Database.
                           This application is used to backtest trading strategies,
                           gather market data quickly, and plot results.
                        </p>
                    </div>
                </div>
            </div>
        </section>
    )
}
export default Skills

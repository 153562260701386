
import React from 'react'
import './work.css'
import Portfolio1 from '../../assets/choose_your_own_adventure_pic.png'
import Portfolio2 from '../../assets/bb_predictions.png'
import Portfolio3 from '../../assets/sleepyp_pic.png'
// import Portfolio4 from '../../assets/portfolio-4.png'
// import Portfolio5 from '../../assets/portfolio-5.png'
// import Portfolio6 from '../../assets/portfolio-6.png'


export const Work = () => {
  return (
    <section id='work'>
        <h2 className='workTitle'>Projects</h2>
        <span className='worksDesc'>
          In the meantime, while I'm not working, I have started exploring new projects
          to advance my skillset. Though not all of them are complete, they all
          attributed in taking the initiative to learn new languages or frameworks
          and advance my understanding of how software works. Below are a few of
          my projects:
        </span>
        <div className='worksImgs'>
            <a href="https://github.com/jcillay/ChooseYourAdventure">

              <img src={Portfolio1} alt="" className=' worksImg'/>
              <h1 className='worksHeader'>Choose Your Own Adventure</h1>
            </a>
            <p className="portfolio-description">
              This project uses Python, Flask, and React to create a trip itinerary
              from anywhere in the US. GPT-turbo, from openai, is used to generate the itinerary
              while the cities and states are all gathered from Geo-DB on rapid api
              and stored in a sqlite Database. For whatever city is generated, SERP API is
              then used to find local google events to go along with the itinerary.
              The above link is to the public github repo, feel free to clone the
              repository and test it out!
            </p>
            <a href="https://github.com/jcillay/baseball-predictions">
            <img src={Portfolio2} alt="" className='worksImg'/>
            <h1 className='worksHeader'>Baseball Predictions</h1>
            </a>
            <p className="portfolio-description">
              This project uses Python and a PostgresDB to store info on
              MLB games and stats from the SportsRadar API and make predictions
              on upcoming games. Using that data, attempts to train a scikit-learn
              RandomForestClassifier to predict the winner of an upcoming game.
              This project is not completely finished or polished, but highlights
              my knowledge on python and my ability to create an Object Relational Mapping
              (ORM) for uploading and storing data in a SQL database. Please browse
              through this code base and email me with any questions or concerns!
            </p>
            <a href="http://www.sleepypsports.com/">
            <img src={Portfolio3} alt="" className='worksImg'/>
            <h1 className='worksHeader'>Blog Website</h1>
            </a>
            <p className="portfolio-description">
              This project was an attempt at deploying my own software to the
              public. Using a Django framework with bootstrap and a sqlite DB,
              this project allows users to create an account, login, or logout.
              After creating account and logging in, users can make posts, create posts
              edit their profile, or delete posts. The website is running on
              Linode servers and deployed using Apache. Sleepy P is meant for
              blogging about sleeper sports picks!
            </p>
            {/* <img src={Portfolio4} alt="" className='worksImg'/>
            <img src={Portfolio5} alt="" className='worksImg'/>
            <img src={Portfolio6} alt="" className='worksImg'/> */}
        </div>
    </section>
  )
}

export default Work

import {React, useRef} from 'react'

import FacebookIcon from "../../assets/facebook-icon.png"
import LinkedIn from "../../assets/linkedin.png"
import InstagramIcon from "../../assets/instagram.png"
// import TwitterIcon from "../../assets/twitter.png"
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import emailjs from "@emailjs/browser"



import "./contact.css"

const Contact = () => {
  const form = useRef()
  const notify = () => toast.success("Thank you, email sent!", {
    position: "bottom-center",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });
  const sendEmail = (e) => {
    e.preventDefault();
    emailjs.sendForm("service_hvi28jj", "template_jjfa7qq", form.current,
    "aDEViBue1AUi2TK63")
    .then((res) => {
      notify()
      e.target.reset()
    })
    .catch((err) => {
      console.error(err.text)
    })
  }
  return (
    <section id='contactPage'>
        <div id="contact">
            <ToastContainer
              position="bottom-center"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              theme="light"
            />
            <h1 className="contactPageTitle">Contact Me</h1>
            <span className='contactDesc'>Please fill out the form below to discuss work!</span>
            <form className="contactForm" method='POST' ref={form} onSubmit={sendEmail}>
              <input type="text" className='name' placeholder='Your Name' name="senders_name"/>
              <input type="email" className='email' placeholder='Your Email' name="senders_email"/>
              <textarea className="msg" name="message" rows="5" placeholder='Additional Info'/>
              <button type="submit" value="Send" className='submitBtn'>Submit Query!</button>
              <div className='links'>
                <a href="https://www.facebook.com/jake.cillay/">
                  <img src={FacebookIcon} alt="Facebook" className="link"/>

                </a>
                <a href="https://www.linkedin.com/in/jake-cillay/">
                  <img src={LinkedIn} alt="Facebook" className="link"/>

                </a>
                <a href="https://www.instagram.com/whitepaint55/">
                  <img src={InstagramIcon} alt="Instagram" className="link"/>
                </a>
              </div>
            </form>
        </div>
    </section>
  )
}

export default Contact

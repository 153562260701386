import React from 'react';
import { Link } from 'react-scroll';
import logo from "../../assets/logo.png";
import contact from "../../assets/contact.png";

import "./navbar.css"

const Navbar = () => {
  return (
    <nav className='navbar'>
        <img src={logo} alt="Logo" className='logo' />
        <div className='desktopMenu'>
            <Link activeClass="active" to="intro" spy={true} smooth={true}
                  duration={500} offset={-50} className='desktopMenuListItem'>Home
            </Link>
            <Link activeClass="active" to="skills" spy={true} smooth={true}
                  duration={500} offset={-70} className='desktopMenuListItem'>Work
            </Link>
            <Link activeClass="active" to="work" spy={true} smooth={true}
                  duration={500} offset={-70} className='desktopMenuListItem'>Projects
            </Link>
        </div>
        <button className='contactMeBtn' onClick={() => {
              document.getElementById("contact").scrollIntoView({behavior: "smooth"});
            }}>
            <img src={contact} alt="Contact me" className="contactMeImg"/> Contact Me
        </button>
    </nav>
  )
}

export default Navbar
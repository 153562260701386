import React from 'react'
import "./footer.css"

const Footer = () => {
  return (
    <footer className='footer'>
        Copyright &#169; 2023 Jacob Cillay. All Rights Reserved.
    </footer>
  )
}

export default Footer
